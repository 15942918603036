import React, { useState, useEffect } from "react";
import axios from "axios";
import * as yup from "yup";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { alert } from "devextreme/ui/dialog";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { confirm } from "devextreme/ui/dialog";

export default function WalkMissionSurvey() {
  const { authToken } = useParams();
  const [auth, setAuth] = useState();
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const schema = yup
    .object({
      walk_ans_01: yup.string().required("1번 항목에 대한 응답을 선택해 주세요.").nullable(),
      walk_ans_01_4_txt: yup.string().when("walk_ans_01", {
        is: "4",
        then: yup.string().required("기타를 입력해주세요").nullable(),
      }),
      walk_ans_02: yup.string().required("2번 항목에 대한 응답을 선택해 주세요.").nullable(),
      walk_ans_02_6_txt: yup.string().when("walk_ans_02", {
        is: "6",
        then: yup.string().required("기타를 입력해주세요").nullable(),
      }),
      walk_ans_03: yup.string().required("3번 항목에 대한 응답을 선택해 주세요.").nullable(),
      walk_ans_04: yup.string().required("4번 항목에 대한 응답을 선택해 주세요.").nullable(),
      walk_ans_05: yup.string().required("5번 항목에 대한 응답을 선택해 주세요.").nullable(),
      walk_ans_06: yup.string().required("6번 항목에 대한 응답을 선택해 주세요.").nullable(),
      walk_ans_07: yup.string().required("7번 항목에 대한 응답을 선택해 주세요.").nullable(),
      walk_ans_07_6_txt: yup.string().when("walk_ans_07", {
        is: "6",
        then: yup.string().required("기타를 입력해주세요").nullable(),
      }),
      walk_ans_08: yup.string().required("8번 항목에 대한 응답을 선택해 주세요.").nullable(),
      walk_ans_08_1: yup.string().nullable(),
      walk_ans_09: yup.string().required("9번 항목에 대한 응답을 선택해 주세요.").nullable(),
      walk_ans_10: yup.string().required("10번 항목에 대한 답변을 작성해 주세요.").nullable(),
      walk_ans_11: yup.array().required("11번 항목에 대한 응답을 선택해 주세요.").nullable(),
      walk_ans_12: yup.string().nullable(),
      walk_ans_13: yup.string().required("13번 항목에 대한 응답을 선택해 주세요.").nullable(),
      walk_ans_14: yup
        .string()
        .required("설문참여 보상수령을 위한 휴대전화 번호를 기입해 주세요.")
        .nullable(),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const tel = watch("walk_ans_14");

  useEffect(() => {
    if (tel) {
      if (tel.length === 10) {
        setValue("walk_ans_14", tel.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
      }
      if (tel.length === 13) {
        setValue("walk_ans_14", tel.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
      }
    }
  }, [tel]);

  useEffect(() => {
    reset({ walk_ans_11: null });
  }, [reset]);

  useEffect(() => {
    async function getAuth() {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/user/user-info`, {
          headers: {
            "Content-Type": "application/json",
            authorization: authToken,
          },
        });
        setAuth(data.user);
      } catch (err) {
        alert(err.response.data.message, "오류");
      }
    }
    getAuth();
  }, [authToken]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const onSubmit = async (data) => {

    let result = await confirm("설문을 제출 하시겠습니까?", "제출확인");

    if (!result) {
      return false;
    }

    // 타입 오류 때문에 형변환한 로직
    const walk_11 = data.walk_ans_11;
    data.walk_ans_11 = "";
    data.walk_ans_11 += walk_11.join();

    if (data.walk_ans_01 !== "4") delete data.walk_ans_01_4_txt;
    if (data.walk_ans_02 !== "6") delete data.walk_ans_02_6_txt;
    if (data.walk_ans_07 !== "6") delete data.walk_ans_07_4_txt;
    data.user_id = auth.user_id;

    // 2023년 당뇨 설문 완료 표시
    data.is_survey = "2023";

    console.log(data);
    // api 만들기
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/walk-mission/survey`, data, {
        headers: {
          "Content-Type": "application/json",
          authorization: authToken,
        },
      });

      navigate("/survey-complete", { state: { url: null } });
    } catch (error) {
      // alert(err.response.data.message, "오류");
    }
  };

  return (
    <div className="page-wrap survey">
      <div className="page-tit">
        <span className="page-tit-txt">운동 관리(걷기 미션) 만족도 조사</span>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-content-wrap">
          <div className="table-container">
            {/* 1번 질문 */}
            <div className="section-tit top">
              <span>
                <b>1.</b>&nbsp;&nbsp;해당 서비스를 알게 된 가장 직접적인 계기는 무엇입니까?
              </span>
            </div>
            <div className="section-answer">
              <span className="selection">
                <input
                  id="walk_ans_01_1"
                  className="radio-select"
                  value="1"
                  type="radio"
                  name="walk_ans_01"
                  {...register("walk_ans_01")}
                />
                <label htmlFor="walk_ans_01_1">담당 진료의의 추천을 통해</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_01_2"
                  className="radio-select"
                  value="2"
                  type="radio"
                  name="walk_ans_01"
                  {...register("walk_ans_01")}
                />
                <label htmlFor="walk_ans_01_2">가족, 주위 사람들의 권유를 통해</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_01_3"
                  className="radio-select"
                  value="3"
                  type="radio"
                  name="walk_ans_01"
                  {...register("walk_ans_01")}
                />
                <label htmlFor="walk_ans_01_3">홍보 포스터를 통해</label>
              </span>
              <span className="selection etc">
                <span>
                  <input
                    id="walk_ans_01_4"
                    className="radio-select"
                    value="4"
                    type="radio"
                    name="walk_ans_01"
                    {...register("walk_ans_01")}
                  />
                  <label htmlFor="walk_ans_01_4">기타</label>
                </span>
                <input
                  id="walk_ans_01_4_txt"
                  className="textbox"
                  type="text"
                  disabled={watch("walk_ans_01") !== "4"}
                  {...register("walk_ans_01_4_txt")}
                />
              </span>
              {errors["walk_ans_01"]?.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors["walk_ans_01"]?.message}</div>
                </div>
              )}
            </div>

            {/* 2번 질문 */}
            <div className="section-tit">
              <span>
                <b>2.</b>&nbsp;&nbsp;해당 서비스에 참여하게 된 가장 큰 동기는 무엇입니까?
              </span>
            </div>
            <div className="section-answer">
              <span className="selection">
                <input
                  id="walk_ans_02_1"
                  className="radio-select"
                  value="1"
                  type="radio"
                  name="walk_ans_02"
                  {...register("walk_ans_02")}
                />
                <label htmlFor="walk_ans_02_1">
                  개인별 관리를 미리 경험할 수 있어서 또는 건강을 관리하기 위해
                </label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_02_2"
                  className="radio-select"
                  value="2"
                  type="radio"
                  name="walk_ans_02"
                  {...register("walk_ans_02")}
                />
                <label htmlFor="walk_ans_02_2">가족력, 유전적 요인에 대한 걱정 때문에</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_02_3"
                  className="radio-select"
                  value="3"
                  type="radio"
                  name="walk_ans_02"
                  {...register("walk_ans_02")}
                />
                <label htmlFor="walk_ans_02_3">
                  현재 질환 치료의 의학적인 발전에 기여할 수 있어서
                </label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_02_4"
                  className="radio-select"
                  value="4"
                  type="radio"
                  name="walk_ans_02"
                  {...register("walk_ans_02")}
                />
                <label htmlFor="walk_ans_02_4">여러가지 의료적 혜택을 받을 수 있어서</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_02_5"
                  className="radio-select"
                  value="5"
                  type="radio"
                  name="walk_ans_02"
                  {...register("walk_ans_02")}
                />
                <label htmlFor="walk_ans_02_5">의료진의 권유로 인해</label>
              </span>
              <span className="selection etc">
                <span>
                  <input
                    id="walk_ans_02_6"
                    className="radio-select"
                    value="6"
                    type="radio"
                    name="walk_ans_02"
                    {...register("walk_ans_02")}
                  />
                  <label htmlFor="walk_ans_02_6">기타</label>
                </span>
                <input
                  id="walk_ans_02_6_txt"
                  className="textbox"
                  type="text"
                  disabled={watch("walk_ans_02") !== "6"}
                  {...register("walk_ans_02_6_txt")}
                />
              </span>
              {errors["walk_ans_02"]?.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors["walk_ans_02"]?.message}</div>
                </div>
              )}
            </div>

            {/* 3번 질문 */}
            <div className="section-tit">
              <span>
                <b>3.</b>&nbsp;&nbsp;서비스에 대해 전반적으로 만족하십니까?
              </span>
            </div>
            <div className="section-answer">
              <span className="selection">
                <input
                  id="walk_ans_03_1"
                  className="radio-select"
                  value="1"
                  type="radio"
                  name="walk_ans_03"
                  {...register("walk_ans_03")}
                />
                <label htmlFor="walk_ans_03_1">매우 그렇다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_03_2"
                  className="radio-select"
                  value="2"
                  type="radio"
                  name="walk_ans_03"
                  {...register("walk_ans_03")}
                />
                <label htmlFor="walk_ans_03_2">그렇다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_03_3"
                  className="radio-select"
                  value="3"
                  type="radio"
                  name="walk_ans_03"
                  {...register("walk_ans_03")}
                />
                <label htmlFor="walk_ans_03_3">보통이다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_03_4"
                  className="radio-select"
                  value="4"
                  type="radio"
                  name="walk_ans_03"
                  {...register("walk_ans_03")}
                />
                <label htmlFor="walk_ans_03_4">그렇지 않다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_03_5"
                  className="radio-select"
                  value="5"
                  type="radio"
                  name="walk_ans_03"
                  {...register("walk_ans_03")}
                />
                <label htmlFor="walk_ans_03_5">매우 그렇지 않다</label>
              </span>
              {errors["walk_ans_03"]?.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors["walk_ans_03"]?.message}</div>
                </div>
              )}
            </div>

            {/* 4번 질문 */}
            <div className="section-tit">
              <span>
                <b>4.</b>&nbsp;&nbsp;서비스의 목적과 목표에 관하여 알고 계십니까?
              </span>
            </div>
            <div className="section-answer">
              <span className="selection">
                <input
                  id="walk_ans_04_1"
                  className="radio-select"
                  value="1"
                  type="radio"
                  name="walk_ans_04"
                  {...register("walk_ans_04")}
                />
                <label htmlFor="walk_ans_04_1">매우 그렇다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_04_2"
                  className="radio-select"
                  value="2"
                  type="radio"
                  name="walk_ans_04"
                  {...register("walk_ans_04")}
                />
                <label htmlFor="walk_ans_04_2">그렇다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_04_3"
                  className="radio-select"
                  value="3"
                  type="radio"
                  name="walk_ans_04"
                  {...register("walk_ans_04")}
                />
                <label htmlFor="walk_ans_04_3">보통이다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_04_4"
                  className="radio-select"
                  value="4"
                  type="radio"
                  name="walk_ans_04"
                  {...register("walk_ans_04")}
                />
                <label htmlFor="walk_ans_04_4">그렇지 않다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_04_5"
                  className="radio-select"
                  value="5"
                  type="radio"
                  name="walk_ans_04"
                  {...register("walk_ans_04")}
                />
                <label htmlFor="walk_ans_04_5">매우 그렇지 않다</label>
              </span>
              {errors["walk_ans_04"]?.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors["walk_ans_04"]?.message}</div>
                </div>
              )}
            </div>

            {/* 5번 질문 */}
            <div className="section-tit">
              <span>
                <b>5.</b>&nbsp;&nbsp;본 서비스가 당뇨병 예방에 도움이 될 것으로 생각하십니까?
              </span>
            </div>
            <div className="section-answer">
              <span className="selection">
                <input
                  id="walk_ans_05_1"
                  className="radio-select"
                  value="1"
                  type="radio"
                  name="walk_ans_05"
                  {...register("walk_ans_05")}
                />
                <label htmlFor="walk_ans_05_1">매우 그렇다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_05_2"
                  className="radio-select"
                  value="2"
                  type="radio"
                  name="walk_ans_05"
                  {...register("walk_ans_05")}
                />
                <label htmlFor="walk_ans_05_2">그렇다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_05_3"
                  className="radio-select"
                  value="3"
                  type="radio"
                  name="walk_ans_05"
                  {...register("walk_ans_05")}
                />
                <label htmlFor="walk_ans_05_3">보통이다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_05_4"
                  className="radio-select"
                  value="4"
                  type="radio"
                  name="walk_ans_05"
                  {...register("walk_ans_05")}
                />
                <label htmlFor="walk_ans_05_4">그렇지 않다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_05_5"
                  className="radio-select"
                  value="5"
                  type="radio"
                  name="walk_ans_05"
                  {...register("walk_ans_05")}
                />
                <label htmlFor="walk_ans_05_5">매우 그렇지 않다</label>
              </span>
              {errors["walk_ans_05"]?.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors["walk_ans_05"]?.message}</div>
                </div>
              )}
            </div>



            {/* 6번 질문 */}
            <div className="section-tit">
              <span>
                <b>6.</b>&nbsp;&nbsp;당뇨병 예방 및 관리를 위해 본 서비스를 장기간 이용할 생각이 있으십니까?
              </span>
            </div>
            <div className="section-answer">
              <span className="selection">
                <input
                  id="walk_ans_06_1"
                  className="radio-select"
                  value="1"
                  type="radio"
                  name="walk_ans_06"
                  {...register("walk_ans_06")}
                />
                <label htmlFor="walk_ans_06_1">매우 그렇다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_06_2"
                  className="radio-select"
                  value="2"
                  type="radio"
                  name="walk_ans_06"
                  {...register("walk_ans_06")}
                />
                <label htmlFor="walk_ans_06_2">그렇다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_06_3"
                  className="radio-select"
                  value="3"
                  type="radio"
                  name="walk_ans_06"
                  {...register("walk_ans_06")}
                />
                <label htmlFor="walk_ans_06_3">보통이다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_06_4"
                  className="radio-select"
                  value="4"
                  type="radio"
                  name="walk_ans_06"
                  {...register("walk_ans_06")}
                />
                <label htmlFor="walk_ans_06_4">그렇지 않다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_06_5"
                  className="radio-select"
                  value="5"
                  type="radio"
                  name="walk_ans_06"
                  {...register("walk_ans_06")}
                />
                <label htmlFor="walk_ans_06_5">매우 그렇지 않다</label>
              </span>
              {errors["walk_ans_06"]?.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors["walk_ans_06"]?.message}</div>
                </div>
              )}
            </div>

            {/* 7번 질문 */}
            <div className="section-tit">
              <span>
                <b>7.</b>&nbsp;&nbsp;서비스 참여 중 느낀 가장 좋은 점은 무엇입니까?
              </span>
            </div>
            <div className="section-answer">
              <span className="selection">
                <input
                  id="walk_ans_07_1"
                  className="radio-select"
                  value="1"
                  type="radio"
                  name="walk_ans_07"
                  {...register("walk_ans_07")}
                />
                <label htmlFor="walk_ans_07_1">당뇨 예방에 대한 지식 및 정보 습득</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_07_2"
                  className="radio-select"
                  value="2"
                  type="radio"
                  name="walk_ans_07"
                  {...register("walk_ans_07")}
                />
                <label htmlFor="walk_ans_07_2">지속적인 건강에 대한 관심과 자극</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_07_3"
                  className="radio-select"
                  value="3"
                  type="radio"
                  name="walk_ans_07"
                  {...register("walk_ans_07")}
                />
                <label htmlFor="walk_ans_07_3">보상을 통한 동기부여(포인트 수령) </label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_07_4"
                  className="radio-select"
                  value="4"
                  type="radio"
                  name="walk_ans_07"
                  {...register("walk_ans_07")}
                />
                <label htmlFor="walk_ans_07_4">생활 습관 개선</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_07_5"
                  className="radio-select"
                  value="5"
                  type="radio"
                  name="walk_ans_07"
                  {...register("walk_ans_07")}
                />
                <label htmlFor="walk_ans_07_5">시공간의 제약이 없음(원하는 때, 의료기관 방문 없이 당뇨 예방 및 관리 가능)</label>
              </span>
              <span className="selection etc">
                <span>
                  <input
                    id="walk_ans_07_6"
                    className="radio-select"
                    value="6"
                    type="radio"
                    name="walk_ans_07"
                    {...register("walk_ans_07")}
                  />
                  <label htmlFor="walk_ans_07_6">기타</label>
                </span>
                <input
                  id="walk_ans_07_6_txt"
                  className="textbox"
                  type="text"
                  disabled={watch("walk_ans_07") !== "6"}
                  {...register("walk_ans_07_6_txt")}
                />
              </span>
              {errors["walk_ans_07"]?.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors["walk_ans_07"]?.message}</div>
                </div>
              )}
            </div>

            {/* 8번 질문 */}
            <div className="section-tit">
              <span>
                <b>8.</b>&nbsp;&nbsp;서비스에 전반적으로 만족하십니까?
              </span>
            </div>
            <div className="section-answer">
              <span className="selection">
                <input
                  id="walk_ans_08_1"
                  className="radio-select"
                  value="1"
                  type="radio"
                  name="walk_ans_08"
                  {...register("walk_ans_08")}
                />
                <label htmlFor="walk_ans_08_1">매우 그렇다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_08_2"
                  className="radio-select"
                  value="2"
                  type="radio"
                  name="walk_ans_08"
                  {...register("walk_ans_08")}
                />
                <label htmlFor="walk_ans_08_2">그렇다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_08_3"
                  className="radio-select"
                  value="3"
                  type="radio"
                  name="walk_ans_08"
                  {...register("walk_ans_08")}
                />
                <label htmlFor="walk_ans_08_3">보통이다</label>
              </span>
              <span className="selection column">
                <span>
                  <input
                    id="walk_ans_08_4"
                    className="radio-select"
                    value="4"
                    type="radio"
                    name="walk_ans_08"
                    {...register("walk_ans_08")}
                  />
                  <label htmlFor="walk_ans_08_4">그렇지 않다</label>
                </span>
                <span className="answer_tail">
                  (8-1번 문항을 필수적으로 작성해 주시면 개선에 많은 도움이 됩니다.)
                </span>
              </span>
              <span className="selection column">
                <span>
                  <input
                    id="walk_ans_08_5"
                    className="radio-select"
                    value="5"
                    type="radio"
                    name="walk_ans_08"
                    {...register("walk_ans_08")}
                  />
                  <label htmlFor="walk_ans_08_5">매우 그렇지 않다</label>
                </span>
                <span className="answer_tail">
                  (8-1번 문항을 필수적으로 작성해 주시면 개선에 많은 도움이 됩니다.)
                </span>
              </span>
              {errors["walk_ans_08"]?.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors["walk_ans_08"]?.message}</div>
                </div>
              )}
            </div>

            {/* 8-1번 질문 */}
            <div className="section-tit">
              <span>
                <b>8-1.</b>&nbsp;&nbsp;불만족 이유/개선이 필요한 점을 자유롭게 적어주십시오.
              </span>
            </div>
            <div className="section-answer text">
              <span className="selection">
                <input
                  id="walk_ans_08_1_1"
                  className="textbox"
                  type="text"
                  name="walk_ans_08_1"
                  placeholder="(예) 걸음수 측정 오류/ 주간 리포트 제시 필요 등"
                  {...register("walk_ans_08_1")}
                />
              </span>
              {errors["walk_ans_08_1"]?.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors["walk_ans_08_1"]?.message}</div>
                </div>
              )}
            </div>

            {/* 9번 질문 */}
            <div className="section-tit">
              <span>
                <b>9.</b>&nbsp;&nbsp;서비스의 내용은 전반적으로 이해하기 쉬웠습니까?
              </span>
            </div>
            <div className="section-answer">
              <span className="selection">
                <input
                  id="walk_ans_09_1"
                  className="radio-select"
                  value="1"
                  type="radio"
                  name="walk_ans_09"
                  {...register("walk_ans_09")}
                />
                <label htmlFor="walk_ans_09_1">매우 그렇다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_09_2"
                  className="radio-select"
                  value="2"
                  type="radio"
                  name="walk_ans_09"
                  {...register("walk_ans_09")}
                />
                <label htmlFor="walk_ans_09_2">그렇다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_09_3"
                  className="radio-select"
                  value="3"
                  type="radio"
                  name="walk_ans_09"
                  {...register("walk_ans_09")}
                />
                <label htmlFor="walk_ans_09_3">보통이다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_09_4"
                  className="radio-select"
                  value="4"
                  type="radio"
                  name="walk_ans_09"
                  {...register("walk_ans_09")}
                />
                <label htmlFor="walk_ans_09_4">그렇지 않다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_09_5"
                  className="radio-select"
                  value="5"
                  type="radio"
                  name="walk_ans_09"
                  {...register("walk_ans_09")}
                />
                <label htmlFor="walk_ans_09_5">매우 그렇지 않다</label>
              </span>
              {errors["walk_ans_09"]?.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors["walk_ans_09"]?.message}</div>
                </div>
              )}
            </div>

            {/* 10번 질문 */}
            <div className="section-tit">
              <span>
                <b>10.</b>&nbsp;&nbsp;서비스가 앞으로의 혈당 관리에 도움이 될 것으로 생각하십니까?
              </span>
            </div>
            <div className="section-answer">
              <span className="selection">
                <input
                  id="walk_ans_10_1"
                  className="radio-select"
                  value="1"
                  type="radio"
                  name="walk_ans_10"
                  {...register("walk_ans_10")}
                />
                <label htmlFor="walk_ans_10_1">매우 그렇다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_10_2"
                  className="radio-select"
                  value="2"
                  type="radio"
                  name="walk_ans_10"
                  {...register("walk_ans_10")}
                />
                <label htmlFor="walk_ans_10_2">그렇다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_10_3"
                  className="radio-select"
                  value="3"
                  type="radio"
                  name="walk_ans_10"
                  {...register("walk_ans_10")}
                />
                <label htmlFor="walk_ans_10_3">보통이다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_10_4"
                  className="radio-select"
                  value="4"
                  type="radio"
                  name="walk_ans_10"
                  {...register("walk_ans_10")}
                />
                <label htmlFor="walk_ans_10_4">그렇지 않다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_10_5"
                  className="radio-select"
                  value="5"
                  type="radio"
                  name="walk_ans_10"
                  {...register("walk_ans_10")}
                />
                <label htmlFor="walk_ans_10_5">매우 그렇지 않다</label>
              </span>
              {errors["walk_ans_10"]?.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors["walk_ans_10"]?.message}</div>
                </div>
              )}
            </div>

            {/* 11번 질문 */}
            <div className="section-tit">
              <span>
                <b>11.</b>&nbsp;&nbsp;서비스에서 가장 도움이 되었던 항목을 모두 선택해주십시오.
              </span>
            </div>
            <div className="section-answer">
              <span className="selection">
                <input
                  id="walk_ans_11_1"
                  className="checkbox"
                  value="1"
                  type="checkbox"
                  name="walk_ans_11"
                  {...register("walk_ans_11")}
                />
                <label htmlFor="walk_ans_11_1">걸음수 제시</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_11_2"
                  className="checkbox"
                  value="2"
                  type="checkbox"
                  name="walk_ans_11"
                  {...register("walk_ans_11")}
                />
                <label htmlFor="walk_ans_11_2">하루 미션(하루 10,000걸음 걷기)</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_11_3"
                  className="checkbox"
                  value="3"
                  type="checkbox"
                  name="walk_ans_11"
                  {...register("walk_ans_11")}
                />
                <label htmlFor="walk_ans_11_3">목표지점으로 걷기(1km이상*1회 걷기)</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_11_4"
                  className="checkbox"
                  value="4"
                  type="checkbox"
                  name="walk_ans_11"
                  {...register("walk_ans_11")}
                />
                <label htmlFor="walk_ans_11_4">주간 미션(목표지점으로 걷기를 주 2, 4, 6회 실시)</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_11_5"
                  className="checkbox"
                  value="5"
                  type="checkbox"
                  name="walk_ans_11"
                  {...register("walk_ans_11")}
                />
                <label htmlFor="walk_ans_11_5">당화혈색소 및 허리둘레 측정</label>
              </span>
              {errors["walk_ans_11"]?.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors["walk_ans_11"]?.message}</div>
                </div>
              )}
            </div>

            {/* 12번 질문 */}
            <div className="section-tit">
              <span>
                <b>12.</b>&nbsp;&nbsp;서비스에서 추가되었으면 하는 내용을 적어주십시오
              </span>
            </div>
            <div className="section-answer text">
              <span className="selection">
                <input
                  id="walk_ans_12"
                  className="textbox"
                  type="text"
                  name="walk_ans_12"
                  {...register("walk_ans_12")}
                />
              </span>
              {errors["walk_ans_12"]?.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors["walk_ans_12"]?.message}</div>
                </div>
              )}
            </div>

            {/* 13번 질문 */}
            <div className="section-tit">
              <span>
                <b>13.</b>&nbsp;&nbsp;나의 지인/가족 등 주변에 해당 서비스를 추천하고 싶습니까?
              </span>
            </div>
            <div className="section-answer">
              <span className="selection">
                <input
                  id="walk_ans_13_1"
                  className="radio-select"
                  value="1"
                  type="radio"
                  name="walk_ans_13"
                  {...register("walk_ans_13")}
                />
                <label htmlFor="walk_ans_13_1">매우 그렇다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_13_2"
                  className="radio-select"
                  value="2"
                  type="radio"
                  name="walk_ans_13"
                  {...register("walk_ans_13")}
                />
                <label htmlFor="walk_ans_13_2">그렇다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_13_3"
                  className="radio-select"
                  value="3"
                  type="radio"
                  name="walk_ans_13"
                  {...register("walk_ans_13")}
                />
                <label htmlFor="walk_ans_13_3">보통이다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_13_4"
                  className="radio-select"
                  value="4"
                  type="radio"
                  name="walk_ans_13"
                  {...register("walk_ans_13")}
                />
                <label htmlFor="walk_ans_13_4">그렇지 않다</label>
              </span>
              <span className="selection">
                <input
                  id="walk_ans_13_5"
                  className="radio-select"
                  value="5"
                  type="radio"
                  name="walk_ans_13"
                  {...register("walk_ans_13")}
                />
                <label htmlFor="walk_ans_13_5">매우 그렇지 않다</label>
              </span>
              {errors["walk_ans_13"]?.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors["walk_ans_13"]?.message}</div>
                </div>
              )}
            </div>

            {/* 14번 질문 */}
            <div className="section-tit">
              <span>
                <b>14.</b>&nbsp;&nbsp;소정의 보상품(모바일 기프티콘)을 문자로 받으실 휴대전화 번호를
                기재해 주십시오.
              </span>
            </div>
            <div className="section-answer text">
              <span className="selection">
                <input
                  id="walk_ans_14"
                  className="textbox"
                  type="tel"
                  maxLength="13"
                  name="walk_ans_14"
                  {...register("walk_ans_14")}
                />
              </span>
              {errors["walk_ans_14"]?.message && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors["walk_ans_14"]?.message}</div>
                </div>
              )}
            </div>
            <div className="survey-btn-wrap">
              <button type="submit" className="btn w-150 h-45 c-dark">
                설문 제출
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
