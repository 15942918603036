import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "devextreme/dist/css/dx.light.css";
import { SurveySuccess } from "./pages/SurveySuccess";
import { lazy, Suspense } from "react";
import WalkMissionSurvey from "./pages/WalkMission/WalkMissionSurvey";

const renderLoader = () => <p></p>;

const PredictResultPage = lazy(() => import("./pages/Predict/PredictResultPage"));
const FoodPatternResult = lazy(() => import("./pages/FoodPattern/FoodPatternResult"));
const OgttResult = lazy(() => import("./pages/Ogtt/OgttResult"));
const FoodBloodResult = lazy(() => import("./pages/FoodBlood/FoodBloodResult"));
const PredictSurvey = lazy(() => import("./pages/Predict/PredictSurvey"));
const FoodPatternSurvey = lazy(() => import("./pages/FoodPattern/FoodPatternSurvey"));
const FoodBloodSurvey = lazy(() => import("./pages/FoodBlood/FoodBloodSurvey"));
const OgttSurvey = lazy(() => import("./pages/Ogtt/OgttSurvey"));

function App() {
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PredictResultPage />} />

            {/* 임상정보 */}
            <Route path="/clinical/:authToken" element={<PredictResultPage />} />
            <Route path="/predict-survey/:authToken" element={<PredictSurvey />} />
            {/* <Route path=":authToken" element={<PredictResultPage />} /> */}


            {/* 식이패턴 */}
            <Route path="/pattern/:authToken" element={<FoodPatternResult />} />
            <Route path="/foodpattern-survey/:authToken" element={<FoodPatternSurvey />} />

            {/* 연속혈당 */}
            <Route path="/cgm/:authToken" element={<FoodBloodResult />} />

            <Route path="/foodblood-survey/:authToken" element={<FoodBloodSurvey />} />
            <Route path="/foodblood-result/:authToken" element={<FoodBloodResult />} />

            {/* 혈당모델링 */}
            <Route path="/ogtt/:authToken" element={<OgttResult />} />
            <Route path="/ogtt-survey/:authToken" element={<OgttSurvey />} />
            <Route path="/survey-complete" element={<SurveySuccess />} />

            {/* 걷기미션  */}
            <Route path="/walkmission-survey/:authToken" element={<WalkMissionSurvey />} />

            {/* <Route path="/info/:authToken" element={<UserInfo />} /> */}
          </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
