import React, { useEffect } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../common/helpers";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

export function SurveySuccess() {
  const { state } = useLocation();

  useEffect(() => {
    console.log(state);
  }, [state]);
  return (
    <div className="message-wrap">
      <div className="message">
        <span className="svg-icon svg-icon-3x">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}></SVG>
        </span>
        <span className="message-txt">참여해 주셔서 감사합니다.</span>
        {state.url !== null && <div className="survey-submit-wrap">
          <Link to={state?.url || "/"}>
            <button
              type="button"
              className="btn w-max h-45 c-main m-t-90"
            >
              결과보기로 이동
            </button>
          </Link>
        </div>
        }
      </div>
    </div>
  );
}
